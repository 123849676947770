<template>
  <div class="flex items-center w-full justify-between space-x-4 ml-4">
    <span class="whitespace-nowrap" data-cy="funder-name">
      {{ data.funder_full_name ?? "-" }}
    </span>
    <div
      v-if="canCreateOfferNote"
      v-tooltip="{
        content: $t('OFFERS.NOTES.TRIGGER_DESCRIPTION'),
        maxWidth: 200
      }"
      class="transform"
      :class="data.note ? 'translate-y-0-25' : 'translate-y-1-25 translate-x-1'"
      data-cy="offer-note-prompt-button"
      @click.stop="showPrompt = true"
    >
      <icon-base
        ref="promptTrigger"
        :class="{ 'text-primary': !!data.note || showPrompt }"
        :icon="data.note ? IconOfferNote : IconOfferNoteAdd"
        width="24"
        height="24"
      />
    </div>
  </div>

  <teleport to="#modals">
    <prompt-box
      v-if="canCreateOfferNote && showPrompt"
      class="fixed z-20"
      :style="`top: ${promptTop}px; left: ${promptLeft}px;`"
      :title="
        $t('OFFERS.NOTES.PROMPT_TITLE', {
          funderName: data.funder_full_name ?? '-'
        })
      "
      :description="$t('OFFERS.NOTES.PROMPT_DESCRIPTION')"
      :placeholder="$t('OFFERS.NOTES.TITLE')"
      :value="data.note?.note"
      :actions-disabled="isNoteLoading || isDeleting"
      deletable
      @cancel="showPrompt = false"
      @delete="deleteOfferNote"
      @save="saveOfferNote"
    />
  </teleport>
</template>

<script setup lang="ts">
import { ref } from "vue";
import IconOfferNoteAdd from "@/components/icons/IconOfferNoteAdd.vue";
import IconOfferNote from "@/components/icons/IconOfferNote.vue";
import PromptBox, {
  PROMPT_HEIGHT,
  PROMPT_WIDTH
} from "@/components/ui/PromptBox.vue";
import IconBase from "@/components/ui/IconBase.vue";
import { useStore } from "vuex";
import { usePromiseWrapper } from "@/hooks/common";
import type { IOffer } from "@/models/funders";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";
import { usePermissions } from "@/hooks/auth";
import { Ability, PermissionSubject } from "@/enums/auth";
import { useElementBoundingLimited } from "@/hooks/elements";

const props = defineProps<{ data: IOffer }>();
const { dispatch } = useStore();

const promptTrigger = ref<HTMLDivElement | null>(null);
const showPrompt = ref(false);
const { top: promptTop, left: promptLeft } = useElementBoundingLimited(
  promptTrigger,
  { offsetLeft: PROMPT_WIDTH, offsetTop: PROMPT_HEIGHT }
);
const { showMessage } = useNotification();
const { t } = useI18n();
const { canPerformActionReactive } = usePermissions();

const canCreateOfferNote = canPerformActionReactive(
  PermissionSubject.offerNote,
  Ability.create,
  { offer: props.data.id }
);

const { fetchWrapper: saveOfferNote, loading: isNoteLoading } =
  usePromiseWrapper(async (note: string) => {
    const message = props.data.note
      ? t("OFFERS.NOTES.UPDATED")
      : t("OFFERS.NOTES.ADDED");
    await dispatch("applications/attachOfferNote", {
      offerId: props.data.id,
      note
    });
    showPrompt.value = false;
    showMessage(message);
  });

const { fetchWrapper: deleteOfferNote, loading: isDeleting } =
  usePromiseWrapper(async () => {
    await dispatch("applications/deleteOfferNote", { offerId: props.data.id });
    showPrompt.value = false;
    showMessage(t("OFFERS.NOTES.DELETED"));
  });
</script>
